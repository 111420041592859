import { render, staticRenderFns } from "./LocationsIreland.vue?vue&type=template&id=7c7fac78&scoped=true&"
import script from "./LocationsIreland.vue?vue&type=script&lang=js&"
export * from "./LocationsIreland.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c7fac78",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardsInteractiveMap: require('/opt/build/repo/components/Cards/InteractiveMap.vue').default})
