
  export default {
    name: "LocationsIreland",
    props: {
        all: {
            default: 'false',
            type: String,
        }
    },
    render() {
      const slot = this.$slots.default
      //  return slot.text.replace(/,/g, "")
      return slot
    }
  
  }
  