
  // import mapboxgl from 'mapbox-gl';
  
  export default {
    name: 'InteractiveMap',
    props: {
        region: {
        type: String,
        default: 'England'
        },
    },
    data() {
      return {
        map: null,
        isMapboxLoaded:false,
        england: [
            { name: 'Ashford', coordinates: [0.8775, 51.1480], info: 'TEFL courses in Ashford', link: '/courses/locations/england/ashford', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Ashford_final.jpg' },
            { name: 'Birmingham', coordinates: [-1.8986, 52.4862], info: 'TEFL courses in Birmingham', link: '/courses/locations/england/birmingham', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Birmingham_final.jpeg' },
            { name: 'Bournemouth', coordinates: [-1.8808, 50.7192], info: 'TEFL courses in Bournemouth', link: '/courses/locations/england/bournemouth', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Bournemouth_final.jpg' },
            { name: 'Brighton', coordinates: [-0.1357, 50.8284], info: 'TEFL courses in Brighton', link: '/courses/locations/england/brighton', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Brighton_final.jpeg' },
            { name: 'Bristol', coordinates: [-2.5879, 51.4545], info: 'TEFL courses in Bristol', link: '/courses/locations/england/bristol', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Bristol_final.jpg' },
            { name: 'Chelmsford', coordinates: [0.4685, 51.7356], info: 'TEFL courses in Chelmsford', link: '/courses/locations/england/chelmsford', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Chelmsford_final.jpg' },
            { name: 'Chester', coordinates: [-2.8909, 53.1934], info: 'TEFL courses in Chester', link: '/courses/locations/england/chester', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Chester_final.jpg' },
            { name: 'Exeter', coordinates: [-3.5338, 50.7184], info: 'TEFL courses in Exeter', link: '/courses/locations/england/exeter', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Exeter_final.jpeg' },
            { name: 'Guildford', coordinates: [-0.5676, 51.2362], info: 'TEFL courses in Guildford', link: '/courses/locations/england/guildford', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Guildford_final.jpg' },
            { name: 'Hull', coordinates: [-0.3367, 53.7676], info: 'TEFL courses in Hull', link: '/courses/locations/england/hull', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Hull_final.jpg' },
            { name: 'Leeds', coordinates: [-1.5486, 53.8008], info: 'TEFL courses in Leeds', link: '/courses/locations/england/leeds', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Leeds_final.jpg' },
            { name: 'Leicester', coordinates: [-1.1339, 52.6369], info: 'TEFL courses in Leicester', link: '/courses/locations/england/leicester', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Leicester_final.jpeg' },
            { name: 'Liverpool', coordinates: [-2.9785, 53.4084], info: 'TEFL courses in Liverpool', link: '/courses/locations/england/liverpool', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Liverpool_final.jpg' },
            { name: 'London', coordinates: [-0.1276, 51.5074], info: 'TEFL courses in London', link: '/courses/locations/england/london', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/London.jpg' },
            { name: 'Manchester', coordinates: [-2.2426, 53.4808], info: 'TEFL courses in Manchester', link: '/courses/locations/england/manchester', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Manchester_final.jpeg' },
            { name: 'Milton Keynes', coordinates: [-0.7558, 52.0406], info: 'TEFL courses in Milton Keynes', link: '/courses/locations/england/milton-keynes', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Milton-Keynes_final.jpg' },
            { name: 'Newcastle', coordinates: [-1.6174, 54.9783], info: 'TEFL courses in Newcastle', link: '/courses/locations/england/newcastle', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Newcastle_final.jpg' },
            { name: 'Norwich', coordinates: [1.2993, 52.6309], info: 'TEFL courses in Norwich', link: '/courses/locations/england/norwich', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Norwich_final.jpeg' },
            { name: 'Nottingham', coordinates: [-1.1510, 52.9548], info: 'TEFL courses in Nottingham', link: '/courses/locations/england/nottingham', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Nottingham_final.jpg' },
            { name: 'Plymouth', coordinates: [-4.1427, 50.3755], info: 'TEFL courses in Plymouth', link: '/courses/locations/england/plymouth', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Plymouth_final.jpg' },
            { name: 'Reading', coordinates: [-0.9781, 51.4543], info: 'TEFL courses in Reading', link: '/courses/locations/england/reading', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Reading_final.jpeg' },
            { name: 'Sheffield', coordinates: [-1.4701, 53.3811], info: 'TEFL courses in Sheffield', link: '/courses/locations/england/sheffield', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Sheffield_final.jpg' },
            { name: 'Southampton', coordinates: [-1.4044, 50.9097], info: 'TEFL courses in Southampton', link: '/courses/locations/england/southampton', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Southampton_final.jpeg' }
        ],
        nIreland: [
            { name: 'Belfast', coordinates: [-5.9301, 54.5973], info: 'TEFL courses in Belfast', link: '/courses/locations/northern-ireland/belfast', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Belfast.jpg' }
        ],
        ireland: [
            { name: 'Dublin', coordinates: [-6.2603, 53.3498], info: 'TEFL courses in Dublin', link: '/courses/locations/ireland/dublin', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Dublin.jpg' },
            { name: 'Cork', coordinates: [-8.4863, 51.8985], info: 'TEFL courses in Cork', link: '/courses/locations/ireland/cork', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Cork_final.jpg' },
            { name: 'Galway', coordinates: [-9.0489, 53.2707], info: 'TEFL courses in Galway', link: '/courses/locations/ireland/galway', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Galway_final.jpeg' },
            { name: 'Sligo', coordinates: [-8.4711, 54.2766], info: 'TEFL courses in Sligo', link: '/courses/locations/ireland/sligo', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Sligo_final.jpeg' }
        ],
        scotland: [
            { name: 'Glasgow', coordinates: [-4.2518, 55.8642], info: 'TEFL courses in Glasgow', link: '/courses/locations/scotland/glasgow', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Glasgow_final.jpeg' },
            { name: 'Edinburgh', coordinates: [-3.1883, 55.9533], info: 'TEFL courses in Edinburgh', link: '/courses/locations/scotland/edinburgh', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Edinburgh.jpg' },
            { name: 'Inverness', coordinates: [-4.2247, 57.4778], info: 'TEFL courses in Inverness', link: '/courses/locations/scotland/inverness', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Inverness_final.jpg' },
            { name: 'Aberdeen', coordinates: [-2.0943, 57.1497], info: 'TEFL courses in Aberdeen', link: '/courses/locations/scotland/aberdeen', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Aberdeen_final.jpeg' },
            { name: 'Dundee', coordinates: [-2.9707, 56.4620], info: 'TEFL courses in Dundee', link: '/courses/locations/scotland/dundee', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Dundee_final.jpeg' }
        ],
        wales: [
            { name: 'Cardiff', coordinates: [-3.1791, 51.4816], info: 'TEFL courses in Cardiff', link: '/courses/locations/wales/cardiff', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Cardiff.jpg' },
            { name: 'Swansea', coordinates: [-3.9449, 51.6214], info: 'TEFL courses in Swansea', link: '/courses/locations/wales/swansea', img:'https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Swansea_final.jpg' }
        ],
      };
    },
    head() {
        return {
        link: [
            {
            rel: 'stylesheet',
            href: 'https://api.mapbox.com/mapbox-gl-js/v1.9.0/mapbox-gl.css',
            },
        ],
        script: [
                {
                hid: 'mapboxgl',
                src: 'https://api.mapbox.com/mapbox-gl-js/v2.7.0/mapbox-gl.js',
                defer: true,
                // Changed after script load
                callback: () => { this.isMapboxLoaded = true } 
                }
            ]
        }
    },
    methods: {
        visibilityChanged (isVisible, entry) {
            this.isVisible = isVisible
            if(isVisible)
            this.createMap()
        },
        createMap() {

            // Regional settings
            let mapArray = this.england
            let center = [-1.5, 52.8]
            let zoom = 6
        
            if(this.$props.region === 'Scotland'){
                mapArray = this.scotland   
                center = [-4, 56.5] 
                zoom = 6
            }
            if(this.$props.region === 'Wales'){
                mapArray = this.wales  
                center = [-3.7, 52.1]  
                zoom = 7 
            }
            if(this.$props.region === 'Ireland'){
                mapArray = this.ireland    
                center = [-7.3, 53.2] 
                zoom = 6
            }
            if(this.$props.region === 'nIreland'){
                mapArray = this.nIreland 
                center = [-6.5, 54.5]  
                zoom = 7 
            }

            window.mapboxgl.accessToken = 'pk.eyJ1IjoiamFrZXRlZmwiLCJhIjoiY2lveTg0ZW94MDBjeHZtbTJ3bmRkZzA1aCJ9.EDGQY9zwCqaxTHVGc7DK8w';
            this.map = new window.mapboxgl.Map({
                container: 'mapContainer',
                style: 'mapbox://styles/mapbox/outdoors-v11',
                center,
                zoom,
            });
            this.map.scrollZoom.disable();
            this.map.addControl(new window.mapboxgl.NavigationControl());

            mapArray.forEach(city => {
                const popup = new window.mapboxgl.Popup({ offset: 25, }).setHTML(`
                <h2 class="my-0">${city.name}</h2>
                <img class="rounded-2xl my-4" src="${city.img}" />
                <p>${city.info}</p>
                <a class="button new solid w-full text-sm text-center" href="${city.link}">More info</a>
                `);
        
                new window.mapboxgl.Marker({color: '#F76386'})
                .setLngLat(city.coordinates)
                .setPopup(popup)
                .addTo(this.map);
            });
        },
    }
}
  